import { Content } from '@backstage/core-components';
import { CloudCostsTable } from '@internal/cloud-accounts';
import { CloudAccountCost } from '@internal/cloud-accounts-common';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useUserCloudCosts } from '../../../../hooks/useUserCloudCosts';
import { HomePageHeader } from '../../../home/components/HomePage/header/HomePageHeader';

export const MyCloudCostPage = () => {
  const { value, loading, error } = useUserCloudCosts();

  if (loading) {
    return (
      <>
        <HomePageHeader />
        <Content>
          <p>Loading...</p>
        </Content>
      </>
    );
  } else if (error) {
    return (
      <>
        <HomePageHeader />
        <Content>
          <p>Error: {error.message}</p>
        </Content>
      </>
    );
  }

  const costRecords = value?.reduce((acc, costRecord) => {
    acc[costRecord.accountName] = costRecord;
    return acc;
  }, {} as Record<string, CloudAccountCost>);

  return (
    <>
      <HomePageHeader />
      <Content>
        <Grid spacing={6}>
          <Card>
            <CardContent>
              <CloudCostsTable
                costRecords={costRecords ?? {}}
                splitCurrentMonth
              />
            </CardContent>
          </Card>
        </Grid>
      </Content>
    </>
  );
};
