import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import React, { useEffect, useState } from 'react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { graphql } from '@octokit/graphql';
import type { GraphQlQueryResponseData } from '@octokit/graphql';
import { useGithubToken } from '../utils';
import { Octokit } from '@octokit/rest';

type Membership = {
  node: {
    name: string;
    description: string;
    slug: string;
  };
};

/**
 * Populate the memberships array with the teams the user is a member of, using the provided token.
 * @param token The token to use to query the GitHub API.
 */
async function getMemberships(
  token: string,
  org: string,
): Promise<Membership[]> {
  // get the current user github usersname
  const octokit = new Octokit({ auth: token });
  const user = await octokit.users.getAuthenticated();

  const query = `
        query {
          organization(login: "${org}") {
            teams(first: 100, userLogins: ["${user.data.login}"]) {
              totalCount
              edges {
                node {
                  name
                  description
                  slug
                }
              }
            }
          }
        }
    `;

  return (
    await graphql<GraphQlQueryResponseData>(query, {
      headers: {
        authorization: `token ${token}`,
      },
    })
  ).organization.teams.edges;
}

export const GitHubTeamPicker = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    schema: { title = 'GitHub Teams', description = 'GitHub Teams' },
    required,
    rawErrors = [],
    formData = {},
    formContext,
  } = props;

  const [state, setState] = useState('');

  const [memberships, setMemberships] = useState<Membership[]>([]);

  const onSelect = (_: any, value: string | Membership | null) => {
    if (!value) return;
    if (typeof value === 'string') {
      const selectedTeam = value || '';
      setState(selectedTeam);
      onChange(selectedTeam);
      return;
    }

    const selectedTeam = value.node.slug || '';
    setState(selectedTeam);
    onChange(selectedTeam);
    return;
  };

  const token = useGithubToken();

  useEffect(() => {
    const loadMemberships = async () => {
      if (!token) return;
      setMemberships(await getMemberships(token, formContext.formData.org));
    };
    loadMemberships();
  }, [formContext.formData.org, token]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        value={(state as string) || ''}
        key={formContext.formData.org}
        loading={false}
        options={memberships}
        onChange={onSelect}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.node.name
        }
        freeSolo
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
            placeholder="Memberships"
          />
        )}
      />
    </FormControl>
  );
};

export const GitHubTeamPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GitHubTeamPicker,
    name: 'GitHubTeamPicker',
  }),
);
