import {
  // ANNOTATION_EDIT_URL,
  ANNOTATION_LOCATION,
  GroupEntity,
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { InfoCard, InfoCardVariants, Link } from '@backstage/core-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import {
  EntityRefLinks,
  catalogApiRef,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import React, { useCallback } from 'react';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Alert from '@mui/material/Alert';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import { LinksGroup } from '../../../../shared';
import CallIcon from '@mui/icons-material/Call';

const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <GroupIcon fontSize="inherit" />
    <Box ml={1}>{props.title}</Box>
  </Box>
);

/** @public */
export const GroupProfileCard = (props: {
  variant?: InfoCardVariants;
  showLinks?: boolean;
}) => {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity: group } = useEntity<GroupEntity>();

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, group]);

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  const {
    metadata: { name, description, annotations, links },
    spec: { profile },
  } = group;

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const pagerDutyTeamId = group.metadata.annotations?.['pagerduty.com/team-id'];
  let pagerDutyTeamName =
    group.metadata.annotations?.['pagerduty.com/team-name'];
  let pagerDutyLink = `https://healthline-media.pagerduty.com/teams/${pagerDutyTeamId}`;

  // if no pagerduty team
  if (!pagerDutyTeamId) {
    pagerDutyTeamName = 'N/A';
    pagerDutyLink = `https://healthline-media.pagerduty.com/teams`;
  }

  const displayName = profile?.displayName ?? name;
  const emailHref = profile?.email ? `mailto:${profile.email}` : '#';
  const infoCardAction = (
    <IconButton
      aria-label="Edit"
      title="Edit Metadata"
      component={Link}
      to="https://rvohealth.freshservice.com/support/catalog/items/106"
      size="large"
    >
      <EditIcon />
    </IconButton>
  );

  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {allowRefresh && (
            <IconButton
              aria-label="Refresh"
              title="Schedule entity refresh"
              onClick={refreshEntity}
              size="large"
            >
              <CachedIcon />
            </IconButton>
          )}
          {infoCardAction}
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item md={10} xl={11}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={<Link to={emailHref}>{profile.email}</Link>}
                  secondary="Email"
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Parent Group">
                  <AccountTreeIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  parentRelations.length ? (
                    <EntityRefLinks
                      entityRefs={parentRelations}
                      defaultKind="Group"
                    />
                  ) : (
                    'N/A'
                  )
                }
                secondary="Parent Group"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Tooltip title="Child Groups">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={
                  childRelations.length ? (
                    <EntityRefLinks
                      entityRefs={childRelations}
                      defaultKind="Group"
                    />
                  ) : (
                    'N/A'
                  )
                }
                secondary="Child Groups"
              />
            </ListItem>
            {props?.showLinks && <LinksGroup links={links} />}
            <ListItem>
              <ListItemIcon>
                <Tooltip title="PagerDuty Team">
                  <CallIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={<Link to={pagerDutyLink}>{pagerDutyTeamName}</Link>}
                secondary="Linked PagerDuty Team"
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
