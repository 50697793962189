import React from 'react';
import Grid from '@mui/material/Grid';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import { EntitySoundcheckContent } from '@spotify/backstage-plugin-soundcheck';
import {
  EntitySecurityInsightsContent,
  EntityGithubDependabotContent,
  isSecurityInsightsAvailable,
} from '@internal/github-plugin-security-insights';
import { EntityDXDashboardContent } from '@get-dx/backstage-plugin';
import {
  overviewContent,
  techdocsContent,
  ghInsightsInternalErrMsg,
  ghInsightsNotFoundErrMsg,
  cicdContent,
  deploymentsContent,
} from '../../shared';

export const servicePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/deployments" title="Deployments">
      {deploymentsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/code-scanning"
      title="Code Scanning"
      // shows the tab on entities with the correct annotations already set
      if={isSecurityInsightsAvailable}
    >
      <EntitySecurityInsightsContent
        notFoundErrMsg={ghInsightsNotFoundErrMsg}
        internalErrMsg={ghInsightsInternalErrMsg}
      />
    </EntityLayout.Route>
    <EntityLayout.Route
      path="/dependabot"
      title="Dependabot"
      // shows the tab on entities with the correct annotations already set
      if={isSecurityInsightsAvailable}
    >
      <EntityGithubDependabotContent
        notFoundErrMsg={ghInsightsNotFoundErrMsg}
        internalErrMsg={ghInsightsInternalErrMsg}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/soundcheck" title="Soundcheck">
      <EntitySoundcheckContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dora" title="DORA">
      <EntityDXDashboardContent />
    </EntityLayout.Route>
  </EntityLayout>
);
