import { InfoCard, Progress } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import React from 'react';
import { useAsync } from 'react-use';
import { securityMetricsApiRef } from '../../../api/security-metrics';
// eslint-disable-next-line
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  infoCard: {
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
  },
  alertsCount: {
    alignSelf: 'center',
  },
  critical: {
    color: theme.palette.type === 'dark' ? '#f85149' : '#cf222e',
  },
  high: {
    color: theme.palette.type === 'dark' ? '#db6d28' : '#bc4c00',
  },
  medium: {
    color: theme.palette.type === 'dark' ? '#d29922' : '#bf8600',
  },
  low: {
    color: theme.palette.type === 'dark' ? '#c9d1d9' : '#24292f',
  },
}));

export const ResourceEntityWizCloudIssuesWidget = () => {
  const classes = useStyles();
  const { entity } = useEntity();

  const identityApi = useApi(identityApiRef);
  const securityMetricsApi = useApi(securityMetricsApiRef);

  const { value, loading, error } = useAsync(async (): Promise<any> => {
    const { token } = await identityApi.getCredentials();
    if (!token) {
      throw new Error('unable to retrieve auth token');
    }

    if (
      entity.kind === 'Resource' &&
      (entity.spec?.type === 'aws-account' ||
        entity.spec?.type === 'azure-subscription' ||
        entity.spec?.type === 'gcp-project')
    ) {
      if (entity.metadata.labels!.id !== '') {
        let results;
        try {
          results = await securityMetricsApi.getWizIssuesByResourceIDs([
            entity.metadata.labels!.id,
          ]);
          return results;
        } catch (e) {
          throw new Error('unable to retrieve security metrics');
        }
      }
    }

    return undefined;
  });

  if (loading) return <Progress />;
  else if (error) return <Alert severity="error">{error.message}</Alert>;

  if (value) {
    if (value.results.length > 0) {
      return (
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            <Grid item>
              <Box justifyContent="center" alignItems="center">
                <InfoCard
                  title="Wiz Issues Overview"
                  className={classes.infoCard}
                  deepLink={{
                    link: `/catalog/default/resource/${
                      entity.metadata.labels!.id
                    }/security-metrics`,
                    title: 'View Details',
                  }}
                >
                  <Box
                    data-testid="severitiesContainer"
                    width="100%"
                    display="inline-flex"
                    justifyContent="space-around"
                  >
                    <Box
                      data-testid="severityLevel"
                      ml={2}
                      mr={2}
                      display="flex"
                      className={classes.critical}
                      flexDirection="column"
                    >
                      <Typography className={classes.alertsCount} variant="h1">
                        {value.totalCritical}
                      </Typography>
                      <Typography> Critical severity </Typography>
                    </Box>
                    <Box
                      data-testid="severityLevel"
                      ml={2}
                      mr={2}
                      display="flex"
                      className={classes.high}
                      justifyContent="center"
                      flexDirection="column"
                    >
                      <Typography className={classes.alertsCount} variant="h1">
                        {value.totalHigh}
                      </Typography>
                      <Typography> High severity </Typography>
                    </Box>
                  </Box>
                </InfoCard>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    // return nothing if no wiz response
    return null;
  }
  // return nothing if undefined
  return null;
};
