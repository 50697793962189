import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityResourceSecurityMetricsTab,
  ResourceEntityWizCloudIssuesWidget,
} from '@internal/security-metrics';
import Grid from '@mui/material/Grid';
import { EntitySoundcheckContent } from '@spotify/backstage-plugin-soundcheck';
import React from 'react';
import { EntityWarningContent } from '../EntityWarningContent';
import { ResourceProfileCard } from './CloudAccountProfileCard';
import { CloudCostCard } from './CloudCostCard';

export const resourcePage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <ResourceProfileCard />
        <CloudCostCard />
        <ResourceEntityWizCloudIssuesWidget />
        <Grid item xs={12} md={6}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/security-metrics" title="Security Metrics">
      <EntityResourceSecurityMetricsTab />
    </EntityLayout.Route>
    <EntityLayout.Route path="/soundcheck" title="Soundcheck">
      <EntitySoundcheckContent />
    </EntityLayout.Route>
  </EntityLayout>
);
