import React from 'react';
import Grid from '@mui/material/Grid';
import { Content, Page } from '@backstage/core-components';
import {
  HomePageStarredEntities,
  CustomHomepageGrid,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import {
  AnnouncementsCard,
  NewAnnouncementBanner,
} from '@procore-oss/backstage-plugin-announcements';
import { LoginBannerComponent } from '@internal/plugin-banner';
import { ToolkitTools } from '../Toolkit/Toolkit';
import { HomeSearchBar } from '../SearchBar/SearchBar';
import { HomeBackstageLogo } from '../Logo/Logo';
import { HomeTeamCard } from '../TeamCard/TeamCard';

export const HomePage = () => {
  const defaultConfig = [
    {
      component: <HomeBackstageLogo />,
      x: 4,
      y: 0,
      width: 4,
      height: 3,
      movable: true,
      resizable: true,
      deletable: true,
    },
    {
      component: <HomeSearchBar />,
      x: 0,
      y: 3,
      width: 12,
      height: 2,
      movable: true,
      resizable: true,
      deletable: true,
    },
    {
      component: <HomeTeamCard />,
      x: 0,
      y: 5,
      width: 6,
      height: 5,
      movable: true,
      resizable: true,
      deletable: true,
    },
    {
      component: <HomePageToolkit title="Toolkit" tools={ToolkitTools} />,
      x: 6,
      y: 5,
      width: 6,
      height: 5,
      movable: true,
      resizable: true,
      deletable: true,
    },
  ];

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <Grid item md={12}>
              <LoginBannerComponent />
              <NewAnnouncementBanner />
            </Grid>
          </Grid>
          <CustomHomepageGrid config={defaultConfig}>
            // Insert the allowed widgets inside the grid
            <HomeBackstageLogo />
            <HomeSearchBar />,
            <HomeTeamCard />,
            <HomePageToolkit title="Toolkit" tools={ToolkitTools} />,
            <HomePageStarredEntities />
            <AnnouncementsCard max={3} />
          </CustomHomepageGrid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
