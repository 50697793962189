import { RELATION_OWNED_BY, ResourceEntity } from '@backstage/catalog-model';
import {
  InfoCard,
  InfoCardVariants,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  cloudAccountsApiRef,
  CloudZeroAccountLink,
  CostRecordDataPoint,
} from '@internal/cloud-accounts';
import { CostRecord } from '@internal/cloud-accounts-common';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// eslint-disable-next-line
import { makeStyles } from '@material-ui/core';
import { LineChart } from '@mui/x-charts';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

const CardTitle = (props: { title: string }) => (
  <Box display="flex" alignItems="center">
    <Box ml={1}>{props.title}</Box>
  </Box>
);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  xlarge: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
}));

export const CloudCostCard = (props: { variant?: InfoCardVariants }) => {
  const classes = useStyles();
  const { entity: resource } = useEntity<ResourceEntity>();

  const cloudAccountsApi = useApi(cloudAccountsApiRef);

  const {
    metadata: { description, labels },
  } = resource;

  const ownedByRelations = getEntityRelations(resource, RELATION_OWNED_BY);
  const descriptionSplit = description!.split(' | ');
  const labelsAsDictionary = labels as { [key: string]: any };
  // Get the cloud account type from the spec.type

  const metadata = {
    name: descriptionSplit[0],
    owner: ownedByRelations.length > 0 && (
      <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
    ),
    business_unit: descriptionSplit[1],
    accountId: labelsAsDictionary.id,
    status: labelsAsDictionary.account_status,
  };

  const { value, loading, error } = useAsync(async (): Promise<
    CostRecord[]
  > => {
    const cloudAccounts = await cloudAccountsApi.getCloudAccountsCosts();

    const thisCloudAccount = cloudAccounts.find(
      element => element.accountID === metadata.accountId,
    );

    // return data in ascending date order
    return (
      thisCloudAccount?.costData.sort((a, b) => {
        const aDate = new Date(a.year, a.month);
        const bDate = new Date(b.year, b.month);
        return bDate.getDate() - aDate.getDate();
      }) ?? []
    );
  }, []);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const toDataset = (cloudCosts?: CostRecord[]): CostRecordDataPoint[] => {
    const now = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setUTCFullYear(now.getUTCFullYear() - 1);

    const cloudCostDataPoints =
      cloudCosts
        ?.map(v => ({
          ...v,
          date: new Date(v.year, v.month),
        }))
        .sort((a, b) => b.date.getUTCDate() - a.date.getUTCDate()) ?? [];

    // if the cost data doesn't go back a year, pad it with nulls for missing months
    if (
      cloudCostDataPoints.length === 0 || // there is no cost data
      (cloudCostDataPoints[0].year !== oneYearAgo.getUTCFullYear() && // cloud data doesn't go
        cloudCostDataPoints[0].month !== oneYearAgo.getUTCMonth())
    ) {
      const missingMonths: CostRecordDataPoint[] = [];
      const currentDate = new Date(oneYearAgo);
      // if there is no existing data, pad up to today
      const stopDate =
        cloudCostDataPoints.length > 0
          ? new Date(cloudCostDataPoints[0].year, cloudCostDataPoints[0].month)
          : new Date();

      while (currentDate < stopDate) {
        const year = currentDate.getUTCFullYear();
        const month = currentDate.getUTCMonth();
        if (
          !cloudCostDataPoints.find(v => v.year === year && v.month === month)
        ) {
          missingMonths.push({
            cost: null,
            year,
            month,
            date: new Date(year, month),
          });
        }
        currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);
      }

      return [...missingMonths, ...cloudCostDataPoints];
    }
    return cloudCostDataPoints;
  };

  const dataset = toDataset(value);

  return (
    <Grid item xs={12} md={6}>
      <InfoCard title={<CardTitle title="Cost" />} variant={props.variant}>
        <div className={classes.root}>
          <LineChart
            dataset={dataset}
            xAxis={[
              {
                tickNumber: dataset.length, // required to prevent D3 from inserting extra ticks
                dataKey: 'date',
                scaleType: 'time',
                valueFormatter: (v: number) =>
                  new Date(v).toLocaleString('default', {
                    year: 'numeric',
                    month: 'short',
                  }),
                labelStyle: { fill: '#FFFFFF' },
                tickLabelStyle: { fill: '#FFFFFF' },
              },
            ]}
            yAxis={[
              {
                min: 0,
                max: Math.max(
                  100,
                  dataset.reduce((acc, v) => Math.max(acc, v.cost ?? 0), 0),
                ),
                valueFormatter: (v: number | null) =>
                  v?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }) ?? '',
                labelStyle: { fill: '#FFFFFF' },
                tickLabelStyle: { fill: '#FFFFFF' },
              },
            ]}
            series={[
              {
                dataKey: 'cost',
                valueFormatter: (v: number | null) =>
                  v?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }) ?? '',
              },
            ]}
            height={300}
            grid={{ vertical: true, horizontal: true }}
          />
          <CloudZeroAccountLink accountId={metadata.accountId} />
        </div>
      </InfoCard>
    </Grid>
  );
};
