import { InfoCard, Link } from '@backstage/core-components';
import { EntityRefLinks } from '@backstage/plugin-catalog-react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useUserProfile } from './useUserProfile';
import InfoIcon from '@mui/icons-material/Info';
import { homePlugin } from '@backstage/plugin-home';
import { createReactExtension } from '@backstage/core-plugin-api';

const NoGroupsFound = () => {
  return (
    <>
      <Typography>
        No groups found. See the docs on{' '}
        <Link
          to="/catalog/default/component/backstage/docs/software-catalog/groups/groups/"
          color="primary"
        >
          group management.
        </Link>
      </Typography>
    </>
  );
};

const Contents = () => {
  const profile = useUserProfile();

  const groupEntities = profile?.backstageIdentity?.ownershipEntityRefs.filter(
    entityRef => entityRef.startsWith('group'),
  );

  if (!groupEntities || groupEntities.length === 0) {
    return <NoGroupsFound />;
  }

  return (
    <Typography variant="subtitle1">
      <EntityRefLinks entityRefs={groupEntities} />
    </Typography>
  );
};

const TeamCardTitle = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '.8rem',
      }}
    >
      <Typography variant="inherit">My Teams</Typography>
      <Tooltip title="Your Backstage teams (groups). Entities in backstage are owned by teams. Click on a team to see it's overview page.">
        <InfoIcon />
      </Tooltip>
    </div>
  );
};

export const TeamCard = () => (
  <InfoCard title={<TeamCardTitle />}>
    <Contents />
  </InfoCard>
);

export const HomeTeamCard = homePlugin.provide(
  createReactExtension({
    name: 'Team Card',
    component: {
      sync: TeamCard,
    },
    data: {
      'home.widget.config': {
        layout: {
          height: { minRows: 2 },
          width: { minColumns: 2 },
        },
      },
    },
  }),
);
